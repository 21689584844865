
// Libraries
import { Vue, Component } from "nuxt-property-decorator";
import {
  ButtonText,
  EsyOilContact,
  PriceValues,
  Products,
  PaymentTypes,
} from "~/operations/messages";

// Components
import RouterLink from "~/components/atoms/text/RouterLink.vue";
import AkkordeonListItem from "~/components/molecules/text/AkkordeonListItem.vue";
import PageJumpNavigation from "~/components/molecules/nav/PageJumpNavigation.vue";
import InstallmentPurchase from "~/components/organisms/form/InstallmentPurchase.vue";
import AppStoreBadge from "~/components/molecules/display/badges/AppStoreButton.vue";
import PlayStoreBadge from "~/components/molecules/display/badges/PlayStoreButton.vue";
import ContactBox from "~/components/molecules/display/ContactBox.vue";
import DownloadApp from "~/components/molecules/display/DownloadApp.vue";
// Mixins
import { returnPriceFormatDE } from "~/mixins/formatting";
import { generateMetaTags } from "~/operations/meta-helper-functions";
import sendGtmEvent from "~/operations/send-gtm-event";

@Component({
  components: {
    RouterLink,
    AkkordeonListItem,
    PageJumpNavigation,
    InstallmentPurchase,
    AppStoreBadge,
    PlayStoreBadge,
    ContactBox,
    DownloadApp,
  },
  methods: {
    returnPriceFormatDE,
  },
  jsonld() {
    return {
      "@context": "https://schema.org",
      "@type": "Product",
      name: "e-Peilstab-Plus",
      image: [
        "https://example.com/photos/1x1/photo.jpg",
        "https://example.com/photos/4x3/photo.jpg",
        "https://example.com/photos/16x9/photo.jpg",
      ],
      description:
        "Der neue esyoil e-Peilstab ermöglicht eine unkomplizierte und einfache Messung des Heizölstands in Ihrem Heizöltank. Unsere präzise Messtechnik garantiert höchste Genauigkeit.",
      sku: "1874",
      brand: {
        "@type": "Brand",
        name: "agital.online GmbH",
      },
      offers: {
        "@type": "Offer",
        url: "https://esyoil.com/heizoeltank/e-peilstab",
        priceCurrency: "EUR",
        price: "119.99",
        priceValidUntil: "2022-12-31",
      },
    };
  },
})
export default class EPeilstabCommerceView extends Vue {
  head() {
    return {
      ...generateMetaTags(this.$route.path, "e-peilstab-info"),
      link: [{ rel: "canonical", href: `${this.$route.fullPath}` }],
    };
  }

  ButtonText = ButtonText;
  EsyOilContact = EsyOilContact;
  PriceValues = PriceValues;
  Products = Products;
  PaymentTypes = PaymentTypes;

  beneftitsItems = [
    `Heizöl Preisvergleich von bis zu 820 Händlern`,
    `Preiswecker mit Wunschpreis-oder Fallpreisfunktion`,
    `Kostenlose und schnelle Bestellung per App (Auch per ${PaymentTypes.guaranteedPayment})`,
    `Beratung zur Kaufentscheidung`,
  ];

  usageListItems = [
    {
      imageSrc: `ep-plus-installation.svg`,
      headline: `Einfache Installation`,
      text: `Der ${Products.ePeilstab} lässt sich mit wenig Aufwand installieren. So lässt sich Ihre vorhandene Öltank Füllstandsanzeige gegen den ${Products.ePeilstab} austauschen`,
    },
    {
      imageSrc: `ep-plus-tank-types.svg`,
      headline: `Für alle Tanktypen geeignet`,
      text: `Ob Kugeltank oder Zylindrischer Tank`,
    },
    {
      imageSrc: `ep-plus-analysis.svg`,
      headline: `Messen, vergleichen & sparen`,
      text: `Nur wenn Sie genau wissen, wie viel Heizöl Sie im Tank haben, können Sie bei Ihrer nächsten Heizölbestellung noch besser planen`,
    },
  ];

  FaqList = [
    {
      question: `Wie funktioniert der Öltank Füllstandsmesser ${Products.ePeilstab}?`,
      answer: `Der Füllstand wird nach dem hydrostatischen Prinzip ermittelt. Mittels einer Pumpe wird etwas Luft in den Öltank geblasen, wodurch die im Öltank befindliche Messleitung geleert wird. Der Gegendruck der Flüssigkeit am Tankboden wird von einem Sensor im Gerät aufgenommen. Dadurch kann die Füllhöhe im Öltank ermittelt werden.`,
      visibility: false,
    },
    {
      question: `Für welche Öltanks ist der ${Products.ePeilstab} geeignet?`,
      answer: `Der ${Products.ePeilstab} ist für die Füllstandmessung in allen Tank-, Fass- oder Zisternenformen geeignet, eine funktionierende Entlüftung des Tanks ist Voraussetzung. Für Erdtanks eignet sich der ${Products.ePeilstab} hingegen nur dann, wenn bereits eine Pneumatikuhr vorhanden ist. Der ${Products.ePeilstab} ist für die Installation in trockenen Räumen geeignet. Die zu messende Flüssigkeit muss unempfindlich gegen das Einblasen von Luft sein.`,
      visibility: false,
    },
    {
      question: `Wie baue ich den ${Products.ePeilstab} am besten ein?`,
      answer: `Der ${Products.ePeilstab} lässt sich mit wenig Aufwand installieren. Ge­ge­be­nen­falls vermitteln wir Ihnen einen Fachbetrieb. So lässt sich Ihre vorhandene Füllstandsanzeige, Fadenschwimmer-Anzeige oder pneumatische Wandanzeige gegen den ${Products.ePeilstab} austauschen: Montageanleitung.pdf Für Erdtanks eignet sich der ${Products.ePeilstab} nur, wenn bereits eine Pneumatikuhr vorhanden ist.`,
      visibility: false,
    },
    {
      question: `Wie wird der Öltank Füllstand angezeigt?`,
      answer: `Vor Ort wird der Füllstand auf einem großen elektronischen Display in Liter, Zentimeter oder Prozent digital angezeigt.`,
      visibility: false,
    },
    {
      question: `Reicht ein ${Products.ePeilstab} für eine Batterietankanlage (mehrere Tanks)?`,
      answer: `Das Heizölvolumen in einer Batterietankanlage kann mit einem ${Products.ePeilstab} gemessen werden. Voraussetzung ist eine intakte Entnahmeleitung, die dafür sorgt, dass alle Füllstände der Einzeltanks gleich hoch sind (Prinzip der kommunizierenden Röhren). Das Gesamtvolumen ergibt sich aus der Anzahl Tanks mal dem Volumen eines Tanks. V(ges) = Anz(Tanks) x Vol(Einzeltank).`,
      visibility: false,
    },
  ];

  tracking(val, item) {
    this.FaqList.map((elem) => {
      if (elem.question === item.question) {
        elem.visibility = !elem.visibility;
      }
      return elem;
    });
    if (val.visibility) {
      sendGtmEvent(this, {
        category: "FAQ",
        label: item.question,
        action: "click",
      });
    }
  }

  redirectToPurchasePage() {
    this.$router.push({ path: "/bestellung/e-peilstab-plus" });
  }

  get manualPath() {
    // eslint-disable-next-line no-undef
    return `${process.env.PARTNER_MEDIA_CDN}/downloads/ePeilstab_plus_montageanleitung.pdf`;
  }
}
